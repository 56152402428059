<template>
  <v-sheet class="px-2 transparent charcoalTileMenu" rounded>
    <span :class="textSize + ' paper--text mb-2'">
      {{ item.name }}
    </span>
    <v-spacer />
    <v-card-actions :class="textSizeLarge + ' paper--text mb-2 pa-0'">
      {{ oneDigit(item.calories) }} kCal
      <v-spacer />
    </v-card-actions>
    <v-card-text class="pa-0">
      <span
        :class="textSizeSmall + ' hybrid--text text--lighten-3' + fontWeight"
      >
        FATS {{ oneDigit(item.fats) }} g
      </span>
      <v-spacer />
      <span :class="textSizeSmall + ' gold--text' + fontWeight">
        CARBS {{ oneDigit(item.carbs) }} g
      </span>
      <v-spacer />
      <span :class="textSizeSmall + ' progressActive--text' + fontWeight">
        PROTEINS {{ oneDigit(item.proteins) }} g
      </span>
      <v-spacer />
      <span :class="textSizeSmall + ' silver--text' + fontWeight">
        {{ oneDigit(item.weight) }} g / {{ item.qty }} {{ item.unit }}
      </span>
    </v-card-text>
  </v-sheet>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  name: 'BasicSimpleNutrition',
  mixins: [util],
  data: () => ({}),
  props: {
    item: {
      type: Object
    },
    fontWeight: {
      type: String,
      default: ' font-fontWeight-bold'
    }
  }
}
</script>
